<template>
  <div class="payment-wrapper">
    <div class="payment" ref="modal">
      <div class="payment-caption">
        <p class="payment-caption__text">Первый шаг в твоё профессиональное будущее!</p>
        <button class="close" @click="closeWindow" id="close">
          <img src="@/assets/imgs/close-grey.svg" alt="" class="close__img" />
        </button>
      </div>
      <div class="info">
        <p class="info__text">Для того чтобы я помог тебе получить
          <span class="info__text-1"> уникальный набор</span> возможностей
          и информацию о тебе, всего лишь нужно
          <span class="info__text-2">заполнить форму ниже</span> и <span class="info__text-3">оплатить доступ.</span>
        </p>
        <img src="@/assets/imgs/info-img-2.png" alt="" class="info__img">
      </div>
      <div class="info info-2">
        <p class="info__text">
          Напиши свою <span class="info__text-3">электронку</span>, которая
          станет твоим <span class="info__text-1"> логином для входа</span>
          на сайт.
        </p>
        <img src="@/assets/imgs/payment-robot.png" alt="" class="info__img">
      </div>
      <input type="text" v-model="state.email" placeholder="Логин (email)" class="form__input form__input--first">
      <div class="info info-3">
        <p class="info__text">
          А теперь нам нужно <span class="info__text-1">придумать пароль</span> для твоего<span class="info__text-2">
            личного кабинета.</span>
        </p>
        <img src="@/assets/imgs/payment-robot-2.png" alt="" class="info__img">
      </div>
      <div class="form">
        <label class="form__input">
          <input autocomplete="new-password" placeholder="Пароль" class="form__input-password"
            v-model.trim="state.password" id="password" :type="viewPassword ? 'text' : 'password'" />
          <button tabindex="-1" class="eye-wrapper" type="button" :class="{ 'eye-open': viewPassword }"
            @click="viewPassword = !viewPassword">
          </button>
        </label>
        <label class="form__input">
          <input autocomplete="new-password" placeholder="Повторите пароль" class="form__input-password"
            v-model.trim="state.confirmPassword" id="passwordSecond"
            :type="viewApprovedPassword ? 'text' : 'password'" />
          <button tabindex="-1" class="eye-wrapper" type="button" @click="viewApprovedPassword = !viewApprovedPassword"
            :class="{ 'eye-open': viewApprovedPassword }">
          </button>
        </label>
      </div>
      <div class="info info-4">
        <p class="info__text">
          Чтобы мы могли <span class="info__text-2"> продолжить наше общение</span>, и чтобы в будущем я смог присылать
          тебе полезную информацию для выбора
          профессии - <span class="info__text-2">поставь галочки</span> ✅✅
        </p>
        <img src="@/assets/imgs/payment-robot.png" alt="" class="info__img">
      </div>
      <div class="check-wrapper check-wrapper--first">
        <label class="check">
          <input type="checkbox" class="check__input" v-model="state.agreement">
          <div class="check__box"></div>
        </label>
        <p class="check-text">
          Продолжая регистрацию и осуществляя пользование данным онлайн-сервисом, я принимаю <a
            href="/docs/polzovatelskoe_soglashenie.pdf" target="_blank">условия пользовательского
            соглашения,</a><a href="/docs/ofer.pdf" target="_blank"> договора оферты</a> и даю
          своё
          <a href="/docs/obedenunnoe_soglashenie_na_obrabotky_personalnih_dannih.pdf" target="_blank"
            class="footer__text footer__links-element">согласие на обработку персональных данных</a> на условиях,
          определённых
          <a href="/docs/politica_obrabotki_personalnih_dannih.pdf" target="_blank"
            class="footer__text footer__links-element">Политикой обработки персональных данных.</a>
        </p>
      </div>

      <div class="check-wrapper ">
        <label class="check">
          <input type="checkbox" class="check__input" v-model="state.sideData.sendNews">
          <div class="check__box"></div>
        </label>
        <p class="check-text">
          Выражаю своё согласие на получение от АНО Департамент и его партнёров информации о федеральных проектах
          «Profstories», «Zaсобой», сервисе «Цифровой помощник для выбора профессии», федеральных, региональных и
          местных мероприятиях по профориентации в форме сообщений на указанные при регистрации контактные данные (адрес
          электронной почты, телефон, мессенджеры).
        </p>
      </div>
      <p class="pay-title">Произвести оплату</p>
      <div class="pay">
        <div class="pay-caption">
          <img src="@/assets/imgs/robot-face.png" alt="" class="pay-caption__face">
          <p class="pay-caption__text">Всего:</p>
          <p class="price mar-left-auto">2999 ₽</p>
        </div>
        <div class="finish">
          <div class="border"></div>
          <button class="finish__btn" @click="makePay">Оплатить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, shallowRef } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, email, helpers } from '@vuelidate/validators'
import { errorHandler } from "@/lib/errorHandler";
import { appConfig } from "@/lib/appConfig";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "PaymentMoneyElement",
  setup(props, { emit }) {
    const store = useStore();

    const modal = ref(null);


    const closeWindow = () => {
      store.commit('setPaymentByMoneyModal', false);
      emit('close');
    }
    const viewPassword = shallowRef(false);
    const viewApprovedPassword = shallowRef(false);

    const state = reactive({
      email: '',
      // name: '',
      password: '',
      confirmPassword: '',
      agreement: false,
      sideData: {
        sendNews: false
      }
    })

    const rules = {
      // name: {
      //   required: helpers.withMessage('Некорректное ФИО', required),
      //   fullNameLength: helpers.withMessage('ФИО должно содержать фамилию имя и отчетсво', (value) => value?.split(' ')?.length === 3)
      // },
      email: {
        required: helpers.withMessage('Некорректный email', required),
        email: helpers.withMessage('Некорректный email', email)
      },
      password: {
        required: helpers.withMessage('Пустой пароль', required)
      },
      confirmPassword: {
        required: helpers.withMessage('Пустое подтверждение пароля', required),
        sameAs: helpers.withMessage('Пароли не совпадают', sameAs(computed(() => state.password)))
      },
      agreement: {
        checked: helpers.withMessage('Необходимо дать согласие на обработку персональных данных, принять условия пользовательского соглашения, договора оферты и Политики обработки персональных данных.', sameAs(true))
      }
    }

    const validator = useVuelidate(rules, state);


    const restartEducation = () => {
      closeWindow();
      store.commit('setEducationStep', 0);
    }

    const makePay = async () => {
      if (!await validator.value?.$validate()) {
        return errorHandler({ name: 'customMessage', message: validator.value?.$errors[0]?.$message ?? '' })
      }

      await store.dispatch('submitRegistrationPayment', state);
      // store.dispatch('makePay').then(closeWindow);
    }

    onClickOutside(modal, closeWindow);


    return {
      closeWindow,
      makePay,
      restartEducation,
      viewPassword,
      viewApprovedPassword,
      state,
      appConfig,
      modal
    }
  }
};
</script>

<style scoped>
.payment-wrapper {
  position: fixed;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-width: 100%;
  z-index: 13;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 3rem;
  display: flex;
}

.payment {
  margin: auto;
  padding: 2rem;
  border-radius: 2rem;
  background: #E5E7EE;
  position: relative;
  pointer-events: all;
  max-width: 48.12rem;
  width: 100%;
  pointer-events: all;
  font-family: "Roboto", sans-serif;
  animation: appearance 0.5s ease-in-out;
}

.payment-caption {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.payment-caption__text {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #000;
}

.payment-caption__help {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  font-size: 20px;
}

.payment-caption__help-icon {
  width: 3.4rem;
}

.close {
  margin-left: auto;
}

.close__img {
  width: 2.8125rem;
  height: 2.8125rem;
}

.info {
  margin-top: 3rem;
  border-radius: 1rem;
  background: #FFF;
  position: relative;
  padding: 2.62rem 1.88rem 2.69rem 11.64rem;
  align-items: center;
  display: flex;
}


.info__text {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 133%;
  color: #131313;
}

.info__text span {
  font-weight: 700;
}

.info__text-1 {
  color: #ad4fea;
}

.info__text-2 {
  color: #4a58dd;
}

.info__text-3 {
  color: #4a58dd;
}

.info__img {
  position: absolute;
  left: -0.81rem;
  width: 12.81rem;
}

.pay-title {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 83%;
  letter-spacing: -0.01em;
  color: #000;
}

.info-2 {
  margin-top: 3.5625rem;
  padding: 2rem 17.4rem 2rem 1.88rem;
}

.info-2 .info__img {
  left: unset;
  right: 0.375rem;
  width: 13.25rem;
}

.info-3 {
  margin-top: 2.5625rem;
  padding: 1.56rem 1.88rem 1.56rem 13rem;
}

.info-3 .info__img {
  left: 1.75rem;
  width: 9.38rem;
}

.info-4 {
  margin-top: 1.8125rem;
  padding: 1.56rem 10.875rem 1.56rem 1.875rem;
}

.info-4 .info__img {
  left: unset;
  right: 0.8125rem;
  width: 11.62rem;
}

.pay {
  margin-top: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  background: #FFF;
  position: relative;
  z-index: 10;
}

.pay-caption {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.pay-caption__face {
  width: 5.8125rem;
}

.pay-caption__text {
  font-weight: 400;
  font-size: 2.5rem;
  letter-spacing: -0.01em;
  color: #21262e;
}

.price {
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 0em;
  background: linear-gradient(151deg, #be5aff 15.1%, #949eff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mar-left-auto {
  margin-left: auto;
}

.finish {
  margin-top: 2rem;
}

.border {
  border-radius: 0.125rem;
  background: #e5e7ee;
  height: 0.19rem;
  width: 100%;
}

.finish-content {
  margin-top: 2.31rem;
  display: flex;
  justify-content: space-between;
}

.finish-content__text {
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.finish-content__price {

  font-size: 3rem;
  font-weight: 700;

  background: linear-gradient(92deg, #BE5AFF 14.66%, #949EFF 72.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.finish__btn {
  margin-top: 2rem;
  padding: 1.5rem;
  border-radius: 0.625rem;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
  text-align: center;
  color: #FFF;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  color: #fff;
  width: 100%;
}

.form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form__input {
  border: 1px solid #ad4fea;
  border-radius: 0.5rem;
  color: #131313;
  font-size: 1.5rem;
  line-height: 133%;
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  background: #fff;
}

.form__input--first {
  margin-top: 2rem;
}

.form__input input {
  font-size: 1.5rem;
  line-height: 133%;
  color: #131313;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #131313;
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: none !important;
  color: #131313 !important;
}

.form__input input::placeholder {
  color: #aeafb1;
}

.form__input-password {
  padding: 0;
}

.form__input::placeholder {
  color: #aeafb1;
}

.eye-wrapper {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  flex-shrink: 0;
  background: url('@/assets/imgs/eye-purple.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.eye-open {
  background: url('@/assets/imgs/eye-purple-close.svg');
  background-size: contain;
  background-position: center;
}

.eye {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 2
}

.check-wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  max-width: 100%;
  padding: 1.5rem;
  background: #fff;
  border-radius: 1rem;

}

.check-text {
  line-height: 133%;
  color: #131313;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
}

.check-text a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #4a58dd;
  display: inline;
}

.check-wrapper--first {
  margin-top: 1.6875rem;
}


.check {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.check:hover {
  opacity: 0.8;
}

.check__input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.check__box {
  width: 100%;
  height: 100%;
  background: center / cover no-repeat url("@/assets/imgs/check__box.svg");
}

.check__input:checked~.check__box {
  background: center / cover no-repeat url("@/assets/imgs/check__box-on.svg");
}

@media (max-width: 850px) {
  .payment-wrapper {
    padding: 2rem 1rem;
    background: rgba(0, 0, 0, 0.66);
  }

  .payment {
    padding: 1rem;
    border-radius: 1rem;

  }

  .payment-caption {
    gap: 0.6rem;

  }

  .payment-caption__text {
    font-size: 1.25rem;
  }


  .close__img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .info {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background: #FFF;
    position: relative;
    padding: 1rem 1rem 1rem 5rem;
  }

  .info__text {
    color: #131313;
    font-size: 1rem;
    line-height: normal;
  }

  .info__img {
    position: absolute;
    right: -0.81rem;
    width: 5.89538rem;
  }

  .pay-title {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: normal;
  }

  .pay {
    margin-top: 1.26rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: #FFF;
  }

  .pay-caption {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  .pay-caption__face {
    width: 3rem;
  }

  .pay-caption__text {
    font-size: 1.25rem;
    line-height: normal;
  }

  .price {
    font-size: 1.25rem;
  }

  .finish {
    margin-top: 1rem;
  }

  .finish-content {
    margin-top: 1rem;
  }

  .finish-content__text {
    font-size: 1.5rem;
    line-height: normal;
  }

  .finish-content__price {
    font-size: 1.5rem;
    line-height: normal;
  }

  .finish__btn {
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    line-height: normal;
  }

  button:hover {
    opacity: 1 !important;
  }

  .payment-caption__help-icon {
    width: 1.5rem;
  }

  .payment-caption__help {
    font-size: 1rem;
    gap: 0.3rem;
  }

  .form {
    margin-top: 1rem;
    gap: 0.75rem;
  }

  .form__input {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }

  .form__input input {
    font-size: 0.875rem;
  }

  .info-2 {
    margin-top: 1.5rem;
    padding: 1rem 5rem 1rem 1rem;
  }

  .info-2 .info__img {
    left: unset;
    right: -1.625rem;
    width: 7.25rem;
  }

  .info-3 {
    margin-top: 1rem;
    padding: 1rem 1rem 1rem 5rem;
  }

  .info-3 .info__img {
    left: -0.25rem;
    width: 5rem;
  }

  .info-4 {
    margin-top: 1rem;
    padding: 1rem 5rem 1rem 1rem;
  }

  .info-4 .info__img {
    left: unset;
    right: -1.187rem;
    width: 6.62rem;
  }

  .form__input--first {
    margin-top: 1rem;
  }

  .check-wrapper {
    max-width: 100%;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    align-items: flex-start;
  }

  .check-wrapper--first {
    margin-top: 1rem;
  }

  .check-text {
    font-size: 0.875rem;
    line-height: 1;
  }


  .check {
    width: 1.25rem;
    height: 1.25rem;
  }

  .eye-wrapper {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
